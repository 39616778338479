import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements ControlValueAccessor {
  @Input() title: string = '';
  @Input() type: string = 'text';
  @Input() placeholder: string = '';
  @Input() disableInput: boolean = false;
  @Input() errorInInput: boolean = false;
  @Input() isValidInput: boolean = false;
  @Input() isRequired: boolean = false;

  value: any;
  onChange: any = () => {};
  onTouched: any = () => {};

  setValue(value: any) {
    if (value.value !== null) {
      this.value = value.value;
      this.onChange(value.value);
      this.onTouched();
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
