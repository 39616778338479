<div>
  <p class="font-inter text-[#042F2E] text-sm mb-1">
    {{ title }} <span *ngIf="isRequired" class="text-red-500">*</span>
  </p>
  <input
    [disabled]="disableInput"
    [class.border-red-500]="errorInInput"
    [class.focus:border-red-500]="errorInInput"
    [ngClass]="{
      'focus:ring-red-500/25': errorInInput,
      'border-[#1FAD96]': !errorInInput && isValidInput,
      'border-gray-200': !errorInInput && !isValidInput
    }"
    [value]="value"
    (input)="setValue($event.target)"
    [type]="type"
    class="w-full font-inter text-sm whitespace-nowrap py-3 px-4 text-sm font-medium rounded-lg border bg-white text-[#1F2937] shadow-sm hover:bg-gray-50 focus:border-[#1FAD96] focus:ring-2 focus:ring-teal-500/25 focus:outline-none disabled:opacity-50 disabled:bg-gray-50 disabled:pointer-events-none"
    [placeholder]="placeholder"
  />
</div>
