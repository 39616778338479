export const environment = {
  production: false,
  demo: true,

  apiLogin: 'https://demo.asgcare.dk/api/login',
  apiResetPassword: 'https://demo.asgcare.dk/api/reset-password',

  apiLogOut: 'https://demo.asgcare.dk/api/authentication/logout',

  apiGetUsers: 'https://demo.asgcare.dk/api/users/getAll',
  apiGetUserByID: 'https://demo.asgcare.dk/api/users/get/',
  apiCreateUser: 'https://demo.asgcare.dk/api/users/create',
  apiUpdateUser: 'https://demo.asgcare.dk/api/users/update',
  apiDeleteUser: 'https://demo.asgcare.dk/api/users/delete/',
  apiRestoreUser: 'https://demo.asgcare.dk/api/users/restore/',

  apiGetCompanyInfoFromCVR: 'https://demo.asgcare.dk/api/cvr/company/',
  apiGetCompanyWithSubsidiaryFromCVR:
    'https://demo.asgcare.dk/api/cvr/companyWithSubsidiary/',

  apiGetCPRLogs: 'https://demo.asgcare.dk/api/cpr/log',
  apiGetInfoFromCPR: 'https://demo.asgcare.dk/api/cpr/get',

  apiApplyForSJFInsurance: 'https://demo.asgcare.dk/api/forsia/apply',

  apiApplyForHelvetiaInsurance:
    'https://demo.asgcare.dk/api/application/helvetia',
  apiApplyForHelvetiafleetPolicie:
    'https://demo.asgcare.dk/api/helvetia/riskItem/add',
  apiApplyForHelvetiaInsuranceNoAuth:
    'https://demo.asgcare.dk/api/helvetia/apply',
  apiHelvetiaPrice: 'https://demo.asgcare.dk/api/helvetia/price',

  apiImpersonate: 'https://demo.asgcare.dk/api/impersonate/',

  apiGetSettings: 'https://demo.asgcare.dk/api/settings/get',
  apiUpdateSettings: 'https://demo.asgcare.dk/api/settings/update',

  apiGetDealers: 'https://demo.asgcare.dk/api/dealers/getAll',
  apiGetDealerByID: 'https://demo.asgcare.dk/api/dealers/get/',
  apiDeleteDealerByID: 'https://demo.asgcare.dk/api/dealers/delete/',
  apiUpdateDealer: 'https://demo.asgcare.dk/api/dealers/update',
  apiCreateDealer: 'https://demo.asgcare.dk/api/dealers/create',
  apiRestoreDealer: 'https://demo.asgcare.dk/api/dealers/restore/',

  apiGetInsurancePrices:
    'https://demo.asgcare.dk/api/insurance-info/get/prices',

  apiGetVehicleFromDMRByVIN: 'https://demo.asgcare.dk/api/dmr/vin/',
  apiGetVehicleFromDMRByReg: 'https://demo.asgcare.dk/api/dmr/reg/',

  apiScrapeVehicleFromDMRByVIN: 'https://demo.asgcare.dk/api/dmr/vin/live/',
  apiScrapeVehicleFromDMRByReg: 'https://demo.asgcare.dk/api/dmr/reg/live/',

  apiGetSubsidiaries: 'https://demo.asgcare.dk/api/subsidiaries/getAll',
  apiGetSubsidiaryById: 'https://demo.asgcare.dk/api/subsidiaries/get/',
  apiUpdateSubsidiary: 'https://demo.asgcare.dk/api/subsidiaries/update',

  apiGetLeads: 'https://demo.asgcare.dk/api/leads/getAll',
  apiGetSortedLeads: 'https://demo.asgcare.dk/api/leads/get',
  apiUpdateLead: 'https://demo.asgcare.dk/api/lead-status/update',

  apiGetPriceTag: 'https://demo.asgcare.dk/api/price-tag',
  apiCreateHelvetiaDraft: 'https://demo.asgcare.dk/api/helvetia/draft/',

  apiGetKsfTemplate: 'https://demo.asgcare.dk/api/kf/template',
  apiGetInitialKsfPrice: 'https://demo.asgcare.dk/api/kf/price',
  apiUpdateKsfTemplate: 'https://demo.asgcare.dk/api/kf/template',
  apiApplyForKsf: 'https://demo.asgcare.dk/api/kf/confirm',

  apiApplyForPowerTrain: 'https://demo.asgcare.dk/api/powertrain/apply',
  apiGetPricePowerTrain: 'https://demo.asgcare.dk/api/powertrain/price',
  apiGetAddonsPowerTrain: 'https://demo.asgcare.dk/api/powertrain/addons',
  apiGetPowerTrainLead: 'https://demo.asgcare.dk/api/powertrain/get',

  apiGetCommissionById: 'https://demo.asgcare.dk/api/commission/get/',

  apiGetForsiaPrices: 'https://demo.asgcare.dk/api/forsia/price',
  apiGetForsiaExcessPrices:
    'https://demo.asgcare.dk/api/forsia/referenceTable/excess',
  apiGetForsiaEquipmentTable:
    'https://demo.asgcare.dk/api/forsia/referenceTable/equipment',
  apiGetForsiaMileageTable:
    'https://demo.asgcare.dk/api/forsia/referenceTable/mileage',
  apiForwardLead: 'https://demo.asgcare.dk/api/forsia/insurance-lead',
  apiRefreshToken: 'https://demo.asgcare.dk/api/refresh-token',
};
