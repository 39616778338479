import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HelvetiaAcceptConditions } from '../../models/helvetia.model';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
@Component({
  selector: 'lib-helvetia-accept-conditions',
  templateUrl: './helvetia-accept-conditions.component.html',
  styleUrls: ['./helvetia-accept-conditions.component.scss'],
  animations: [
    // Animation for show steps
    trigger('fadeInOutStep', [
      state(
        'void',
        style({
          opacity: 0,
        })
      ),
      transition('void <=> *', animate(250)),
    ]),
  ],
})
export class HelvetiaAcceptConditionsComponent {
  @Output() nextStepEvent = new EventEmitter();
  @Output() applyForHelvetiaEvent = new EventEmitter<boolean>(); // boolean fleetPolicie to

  @Input() chargingStand: boolean = false;
  @Input() showFleetPolicie: boolean = false;
  @Input() textDirectedToCustomer: boolean = false;
  @Input() applyForInsuranceLoading: boolean = false;
  @Input() helvetiaAcceptConditions: HelvetiaAcceptConditions = {
    acceptedTradingConditions: false,
    acceptedInfoIsCorrect: false,
    acceptedConsent: false,
    acceptedAsgMarketing: false,
    acceptedChargingStandConsultant: false,
    acceptedChargingStandCustomer: false,
  };

  fleetPolicie!: boolean;
  priorTermination: boolean = false;

  errorInChargingStandCustomer: boolean = false;
  errorInChargingStandConsultant: boolean = false;
  errorInTradingConditionsCheckbox: boolean = false;
  errorInInfoIsCorrecCheckbox: boolean = false;
  errorInConsentCheckbox: boolean = false;
  errorInFleetPolicie: boolean = false;
  errorInPriorTermination: boolean = false;

  constructor() {}

  onInit() {
    console.log('*************** INIT OI');
  }

  resetHelvetiaInfo() {
    this.helvetiaAcceptConditions = {
      acceptedTradingConditions: false,
      acceptedInfoIsCorrect: false,
      acceptedConsent: false,
      acceptedAsgMarketing: false,
      acceptedChargingStandConsultant: false,
      acceptedChargingStandCustomer: false,
    };

    this.errorInChargingStandCustomer = false;
    this.errorInChargingStandConsultant = false;
    this.errorInTradingConditionsCheckbox = false;
    this.errorInInfoIsCorrecCheckbox = false;
    this.errorInConsentCheckbox = false;
    this.errorInFleetPolicie = false;
    this.errorInPriorTermination = false;

    this.applyForInsuranceLoading = false;
  }

  applyForHelvetia() {
    this.errorInTradingConditionsCheckbox = false;
    this.errorInInfoIsCorrecCheckbox = false;
    this.errorInConsentCheckbox = false;
    this.errorInChargingStandCustomer = false;
    this.errorInChargingStandConsultant = false;
    this.errorInFleetPolicie = false;
    this.errorInPriorTermination = false;

    if (
      !this.helvetiaAcceptConditions.acceptedTradingConditions ||
      !this.helvetiaAcceptConditions.acceptedInfoIsCorrect ||
      !this.helvetiaAcceptConditions.acceptedConsent ||
      (this.chargingStand &&
        !this.helvetiaAcceptConditions.acceptedChargingStandCustomer) ||
      (this.chargingStand &&
        !this.helvetiaAcceptConditions.acceptedChargingStandConsultant) ||
      (this.showFleetPolicie && this.fleetPolicie == null) ||
      (this.showFleetPolicie && this.fleetPolicie && !this.priorTermination)
    ) {
      this.errorInTradingConditionsCheckbox =
        !this.helvetiaAcceptConditions.acceptedTradingConditions;
      this.errorInInfoIsCorrecCheckbox =
        !this.helvetiaAcceptConditions.acceptedInfoIsCorrect;
      this.errorInConsentCheckbox =
        !this.helvetiaAcceptConditions.acceptedConsent;
      this.errorInChargingStandCustomer =
        this.chargingStand &&
        !this.helvetiaAcceptConditions.acceptedChargingStandCustomer;
      this.errorInChargingStandConsultant =
        this.chargingStand &&
        !this.helvetiaAcceptConditions.acceptedChargingStandConsultant;
      this.errorInFleetPolicie =
        this.showFleetPolicie && this.fleetPolicie == null;
      this.errorInPriorTermination =
        this.showFleetPolicie && this.fleetPolicie && !this.priorTermination;
      return;
    }

    this.applyForHelvetiaEvent.emit(this.fleetPolicie);
  }
}
